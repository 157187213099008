import React from "react";
import { connect } from "react-redux";

//action imports
import { fetchPromotions } from "../../actions/PromotionActions";

class ViewPromotions extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            promotions: this.props.promotions
        };

        this.renderPromotions = this.renderPromotions.bind(this);
    }

    componentDidMount() {
        this.props.fetchPromotions();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.promotions !== this.props.promotions) {
            this.setState({ promotions: nextProps.promotions });
        }
    }

    renderPromotions() {
        //extract state
        const { promotions } = this.state;

        //manual filter
        const filteredPromotions = promotions.filter(sub => sub.city === "dar");

        if (promotions.length !== 0) {
            return (
                <div className={"viewPromoContainer"}>
                    {
                        filteredPromotions.map(sub => {
                            return(
                                <div className={"promoContainer"}>
                                    <h2>{sub.name}</h2>
                                    <p>{sub.city}</p>
                                    <p>{sub.handle}</p>
                                    <p>{sub.club}</p>
                                    <p>{sub.phone}</p>
                                </div>
                            )
                        })
                    }
                </div>
            );
        } else {
            return <p>No Promotion Submissions</p>
        }

    }

    render() {
        return(
            <div>
                {this.renderPromotions()}
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { promotions } = state.promotion;

    return {
        promotions
    }
};

export default connect(mapStateToProps, { fetchPromotions })(ViewPromotions);