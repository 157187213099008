import {
    FETCH_PROMOTION_SUBMISSIONS_SUCCESSFUL,
    PROMOTION_FIELD_CHANGED,
    SEND_PROMOTION_REQUEST,
    SEND_PROMOTION_REQUEST_FAILED,
    SEND_PROMOTION_REQUEST_SUCCESSFUL
} from "../actions/Types";

const INITIAL_STATE = {
    name: "",
    phone: "",
    city: "",
    club: "",
    social: "",
    handle: "",
    promotions: [],

    promoLoading: false,
};

const promotionReducer = (state= INITIAL_STATE, action) => {
    switch (action.type) {
        case PROMOTION_FIELD_CHANGED:
            return { ...state, [action.payload.prop]: action.payload.value };

            //send promo reducer
        case SEND_PROMOTION_REQUEST:
            return { ...state, promoLoading: true };
        case SEND_PROMOTION_REQUEST_SUCCESSFUL:
            return { ...state, promoLoading: false, name: "", phone: "", city: "", social: "", handle: "", club: "" };
        case SEND_PROMOTION_REQUEST_FAILED:
            return { ...state, promoLoading: false, name: "", phone: "", city: "", social: "", handle: "", club: "" };

        case FETCH_PROMOTION_SUBMISSIONS_SUCCESSFUL:
            return { ...state, promotions: action.payload };
        default:
            return state;
    }
}

export default promotionReducer;
