import React, { Component } from 'react'

class Scoring extends Component {
  render() {
    return (
      <div className={"scoringContainer"}>
        <div className={"columnScore"}>
          <h5>Action</h5>
          <h5>Points</h5>
        </div>

        <div className={"rowScore"}>
          <p>For playing up to 60 minutes</p>
          <p>1</p>
        </div>

        <div className={"rowScore"}>
          <p>For playing 60 minutes or more (excluding stoppage time)s</p>
          <p>2</p>
        </div>

        <div className={"rowScore"}>
          <p>For each goal scored by a goalkeeper or defender</p>
          <p>6</p>
        </div>

        <div className={"rowScore"}>
          <p>For each goal scored by a midfielder</p>
          <p>5</p>
        </div>

        <div className={"rowScore"}>
          <p>For each goal scored by a forward</p>
          <p>4</p>
        </div>

        <div className={"rowScore"}>
          <p>	For each goal assist</p>
          <p>3</p>
        </div>

        <div className={"rowScore"}>
          <p>For a clean sheet by a goalkeeper or defender</p>
          <p>4</p>
        </div>

        <div className={"rowScore"}>
          <p>For a clean sheet by a midfielder</p>
          <p>1</p>
        </div>

        <div className={"rowScore"}>
          <p>For playing up to 60 minutes</p>
          <p>1</p>
        </div>

        <div className={"rowScore"}>
          <p>For every 3 shot saves by a goalkeeper</p>
          <p>1</p>
        </div>

        <div className={"rowScore"}>
          <p>For each penalty save</p>
          <p>5</p>
        </div>

        <div className={"rowScore"}>
          <p>For each penalty miss</p>
          <p>-2</p>
        </div>

        {/* <div className={"rowScore"}>
          <p>Bonus points for the best players in a match</p>
          <p>1-3</p>
        </div> */}

        <div className={"rowScore"}>
          <p>For every 2 goals conceded by a goalkeeper or defender</p>
          <p>-1</p>
        </div>

        <div className={"rowScore"}>
          <p>For each yellow card</p>
          <p>-1</p>
        </div>

        <div className={"rowScore"}>
          <p>For each red card</p>
          <p>-3</p>
        </div>

        <div className={"rowScore"}>
          <p>For each own goal</p>
          <p>-2</p>
        </div>

        <div className={"rulesAnswerBox"}>
          <h2>Clean Sheets</h2>
          <p>A clean sheet is awarded for not conceding a goal whilst on the pitch and playing at least 60 minutes (excluding stoppage time).
            If a player has been substituted when a goal is conceded this will not affect any clean sheet bonus.
          </p>

          <h2>Red Cards</h2>
          <p>If a player receives a red card, they will continue to be penalised for goals conceded by their team.
            Red card deductions include any points deducted for yellow cards.
          </p>

          <h2>Assists</h2>
          <p>Assists are awarded to the player from the goal scoring team, who makes the final pass before a goal is scored. An assist is awarded whether the pass was intentional (that it actually creates the chance) or unintentional (that the player had to dribble the ball or an inadvertent touch or shot created the chance).
            If an opposing player touches the ball after the final pass before a goal is scored, significantly altering the intended destination of the ball, then no assist is awarded. Should a touch by an opposing player be followed by a defensive error by another opposing outfield player then no assist will be awarded. If the goal scorer loses and then regains possession, then no assist is awarded.
          </p>

          {/* <h2>Rebounds</h2>
          <p>If a shot on goal is blocked by an opposition player, is saved by a goalkeeper or hits the woodwork, and a goal is scored from the rebound, then an assist is awarded.
          </p> */}

          <h2>Own Goals</h2>
          <p>If a player shoots or passes the ball and forces an opposing player to put the ball in his own net, then an assist is awarded.
          </p>

          <h2>Penalties and Free-Kicks</h2>
          <p>In the event of a penalty or free-kick, the player earning the penalty or free-kick is awarded an assist if a goal is directly scored, but not if he takes it himself, in which case no assist is given.
          </p>

          <h2>Finalising Assists</h2>
          {/* <p>Assist points awarded by Opta within Fantasy Premier League are calculated using additional stats which may differ from other websites. For example, some other sites would not show an assist where a player has won a penalty.
            For the avoidance of doubt, points awarded in-game are subject to change up until one hour after the final whistle of the last match of any given day. Once the points have all been updated on that day, no further adjustments to points will be made.
          </p>  */}
          <p>Coming soon...</p>

          <h2>Bonus Points</h2>
          <p>Coming soon</p>
          {/* <p>The Bonus Points System (BPS) utilises a range of statistics to create a BPS score for every player. The three best performing players in each match will be awarded bonus points. 3 points will be awarded to the highest scoring player, 2 to the second best and 1 to the third.
            <p>Examples of how bonus point ties will be resolved are as follows:</p>
            <ul>
              <li>If there is a tie for first place, Players 1 & 2 will receive 3 points each and Player 3 will receive 1 point.</li>
              <li>If there is a tie for second place, Player 1 will receive 3 points and Players 2 and 3 will receive 2 points each.</li>
              <li>If there is a tie for third place, Player 1 will receive 3 points, Player 2 will receive 2 points and Players 3 & 4 will receive 1 point each.</li>
            </ul>
          </p> */}

          {/* <div className={"columnScore"}>
            <h5>Action</h5>
            <h5>Points</h5>
          </div>

          <div className={"rowScore"}>
            <p>Playing 1 to 60 minutes</p>
            <p>3</p>
          </div>

          <div className={"rowScore"}>
            <p>Playing over 60 minutes</p>
            <p>6</p>
          </div>

          <div className={"rowScore"}>
            <p>Goalkeepers and defenders scoring a goal</p>
            <p>12</p>
          </div>

          <div className={"rowScore"}>
            <p>Midfielders scoring a goal</p>
            <p>18</p>
          </div>

          <div className={"rowScore"}>
            <p>Forwards scoring a goal</p>
            <p>24</p>
          </div>

          <div className={"rowScore"}>
            <p>Assists</p>
            <p>6</p>
          </div>

          <div className={"rowScore"}>
            <p>Goalkeepers and defenders keeping a clean sheet</p>
            <p>12</p>
          </div>

          <div className={"rowScore"}>
            <p>Saving a penalty</p>
            <p>15</p>
          </div>

          <div className={"rowScore"}>
            <p>Save</p>
            <p>2</p>
          </div>

          <div className={"rowScore"}>
            <p>Successful open play cross</p>
            <p>1</p>
          </div>

          <div className={"rowScore"}>
            <p>Creating a big chance (a chance where the receiving player should score)</p>
            <p>3</p>
          </div>

          <div className={"rowScore"}>
            <p>For every 2 clearances, blocks and interceptions (total)</p>
            <p>1</p>
          </div>

          <div className={"rowScore"}>
            <p>For playing up to 60 minutes</p>
            <p>1</p>
          </div>

          <div className={"rowScore"}>
            <p>For playing up to 60 minutes</p>
            <p>1</p>
          </div>

          <div className={"rowScore"}>
            <p>For every 3 recoveries</p>
            <p>1</p>
          </div>

          <div className={"rowScore"}>
            <p>Key pass</p>
            <p>1</p>
          </div>

          <div className={"rowScore"}>
            <p>Successful tackle (net*)</p>
            <p>2</p>
          </div>

          <div className={"rowScore"}>
            <p>Successful dribble</p>
            <p>1</p>
          </div>

          <div className={"rowScore"}>
            <p>Scoring the goal that wins a match</p>
            <p>3</p>
          </div>

          <div className={"rowScore"}>
            <p>70 to 79% pass completion (at least 30 passes attempted)</p>
            <p>2</p>
          </div>

          <div className={"rowScore"}>
            <p>80 to 89% pass completion (at least 30 passes attempted)</p>
            <p>4</p>
          </div>

          <div className={"rowScore"}>
            <p>90%+ pass completion (at least 30 passes attempted)</p>
            <p>6</p>
          </div>

          <div className={"rowScore"}>
            <p>Conceding a penalty</p>
            <p>-3</p>
          </div>

          <div className={"rowScore"}>
            <p>For playing up to 60 minutes</p>
            <p>1</p>
          </div>

          <div className={"rowScore"}>
            <p>For playing up to 60 minutes</p>
            <p>1</p>
          </div>

          <div className={"rowScore"}>
            <p>For playing up to 60 minutes</p>
            <p>1</p>
          </div>

          <div className={"rowScore"}>
            <p>Missing a penalty</p>
            <p>-6</p>
          </div>

          <div className={"rowScore"}>
            <p>For playing up to 60 minutes</p>
            <p>1</p>
          </div>

          <div className={"rowScore"}>
            <p>Yellow card</p>
            <p>-3</p>
          </div>

          <div className={"rowScore"}>
            <p>Red card</p>
            <p>-9</p>
          </div>

          <div className={"rowScore"}>
            <p>Own goal</p>
            <p>-6</p>
          </div>

          <div className={"rowScore"}>
            <p>Missing a big chance</p>
            <p>-3</p>
          </div>

          <div className={"rowScore"}>
            <p>Making an error which leads to a goal</p>
            <p>-3</p>
          </div>

          <div className={"rowScore"}>
            <p>Making an error which leads to an attempt at goal</p>
            <p>-1</p>
          </div>

          <div className={"rowScore"}>
            <p>Being tackled</p>
            <p>-1</p>
          </div>

          <div className={"rowScore"}>
            <p>Conceding a foul</p>
            <p>-1</p>
          </div>

          <div className={"rowScore"}>
            <p>Being caught offside</p>
            <p>-1</p>
          </div>

          <div className={"rowScore"}>
            <p>Shot off target</p>
            <p>-1</p>
          </div> */}

          {/* <p>*Net successful tackles is the total of all successful tackles minus any unsuccessful tackles. Players will not be awarded negative BPS points for this statistic.</p>
          <p>Data is supplied by Opta and once it has been marked as final will not be changed. We will not enter into discussion around any of the statistics used to calculate this score for any individual match.</p>  */}
        </div>
      </div>
    );
  }
}

export default Scoring;