import React from 'react';
import { HashLoader } from 'react-spinners'

export const Spinner = () => {
    return (
        <div className={'spinnerStyle'}>
            <HashLoader
                color={'#BFFB5B'}
                loading={ true }

            />
        </div>
    );
};