import React, { Component } from "react";
import { Table } from "antd";

//import 'antd/lib/collapse/style/css';


class Deadlines extends Component {

  render() {
    return (
      <div className={'rulesAnswerBox'}>
        <div className={"columnScore"}>
          <h5>Gameweek</h5>
          <h5>Deadline</h5>
        </div>

        <div className={"rowScore"}>
          <p>Gameweek1</p>
          <p>Fri 13 Aug 20:30</p>
        </div>

        <div className={"rowScore"}>
          <p>Gameweek2</p>
          <p>Sat 21 Aug 13:00</p>
        </div>

        <div className={"rowScore"}>
          <p>Gameweek3</p>
          <p>Sat 28 Aug 13:00</p>
        </div>

        <div className={"rowScore"}>
          <p>Gameweek4</p>
          <p>Sat 11 Sep 13:00</p>
        </div>

        <div className={"rowScore"}>
          <p>Gameweek5</p>
          <p>Fri 17 Sep 20:30</p>
        </div>

        <div className={"rowScore"}>
          <p>Gameweek6</p>
          <p>Sat 25 Sep 13:00</p>
        </div>

        <div className={"rowScore"}>
          <p>Gameweek7</p>
          <p>Sat 2 Oct 13:00</p>
        </div>

        <div className={"rowScore"}>
          <p>Gameweek8</p>
          <p>Sat 16 Oct 13:00</p>
        </div>

        <div className={"rowScore"}>
          <p>Gameweek9</p>
          <p>Fri 22 Oct 20:30</p>
        </div>

        <div className={"rowScore"}>
          <p>Gameweek10</p>
          <p>Sat 30 Oct 13:00</p>
        </div>

      </div>
    );
  }
}

export default Deadlines;