import React from 'react'

class OfficialPodcast extends React.Component {

  render() {
    return (
      <div className={"fantansyPodcastContainer"}>
        <h1>Coming soon!</h1>
      </div>
    );
  }
}
export default OfficialPodcast;