import React from "react";
import {
    isMobile,
    isAndroid,
    isIOS,
} from "react-device-detect";

class Download extends React.Component {
    componentDidMount() {
        if (isMobile) {
            if(isIOS) {
                window.location.href = "https://apps.apple.com/tz/app/liveball/id1580936245";
            }

            if(isAndroid) {
                window.location.href = "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.firm23.liveball";
            }
        } else {
            alert("View Download Link From Mobile Phone");
        }
    }

    render() {
        return(
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
                <h2 style={{ textAlign: "center", fontFamily: "monospace"}}>Downloading LiveBall</h2>
            </div>
        );
    }
}

export default Download;