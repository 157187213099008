import React from "react";
import AppBanner from "smart-app-banner-react";
import liveballLogo from "../assets/logo/logo.png";

const iosAppInfo = {
  imageUrl: `${liveballLogo}`,
  name: "Liveball",
  publisher: "Firm 23 Ltd.",
  infoText: "Free - on the App Store",
  linkButtonText: "VIEW",
  linkUrl: "https://apps.apple.com/tz/app/liveball/id1580936245",
};

export const IosBanner = () => {
  return (
    <div>
      <AppBanner
        appInfo={iosAppInfo}
        position="bottom"
        onClose={() => {
          console.log("onClose called");
        }}
        hidden={false}
        style={{
          zIndex: 10,
          marginBottom: '10rem'
        }}
      />
    </div>
  );
};
