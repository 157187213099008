import {
    SPONSORSHIP_FIELD_CHANGED,
    SPONSOR_SAVING,
    SPONSOR_SAVED_SUCCESSFUL,
    SPONSOR_SAVED_FAILED,

} from "../actions/Types";

const INITIAL_STATE = {
    companyName: '',
    companyAddress: '',
    companyContact: '',
    companyPhone: '',
    companyEmail: '',
    coreBusiness: '',

    sponsorLoading: false,

};

const SponsorshipReducer = (state= INITIAL_STATE, action) => {
    switch (action.type) {
        case SPONSORSHIP_FIELD_CHANGED:
            console.log({value: action.payload.value})
            return { ...state, [action.payload.prop]: action.payload.value };

        //send sponsor reducer
        case SPONSOR_SAVING:
            return { ...state, sponsorLoading: true };
        case SPONSOR_SAVED_SUCCESSFUL:
            return { ...state, sponsorLoading: false, companyName: '', companyAddress: '', companyContact: '', companyPhone: '', companyEmail: '', coreBusiness: ''};
        case SPONSOR_SAVED_FAILED:
            return { ...state, sponsorLoading: false  };

        default:
            return state;
    }
}

export default SponsorshipReducer;
