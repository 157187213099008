import React from "react";
import AppBanner from "smart-app-banner-react";
import liveballLogo from "../assets/logo/logo.png";

const androidAppInfo = {
  imageUrl: `${liveballLogo}`,
  name: "Liveball",
  publisher: "Firm 23 Ltd.",
  infoText: "Free - In Google Play",
  linkButtonText: "VIEW",
  linkUrl: "https://play.google.com/store/apps/details?id=com.firm23.liveball",
};

export const AndroidBanner = () => {
  return (
    <div>
      <AppBanner
        appInfo={androidAppInfo}
        position="bottom"
        onClose={() => {
          console.log("onClose called");
        }}
        hidden={false}
        style={{
          zIndex: 10,
        }}
      />
    </div>
  );
};
