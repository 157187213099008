import React from "react";
import { connect } from "react-redux";
import { Select } from 'antd';
import {
    isMobile,
} from "react-device-detect";
import 'antd/lib/select/style/css';
import { Parallax, Background } from "react-parallax";

//import actions
import { promotionFieldChanged, sendPromotionRequest } from "../../actions/PromotionActions";

//component imports
import {Input} from "../../common/Input";
import {Button} from "../../common/Button";
import {Spinner} from "../../common/Spinner";

//-> file imports
import featureGraphic from "../../assets/images/featureGraphic.png";

const Option = Select.Option;

class Promotion extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: this.props.name,
            phone: this.props.phone,
            city: this.props.city,
            club: this.props.club,
            social: this.props.social,
            handle: this.props.handle,
            mobile: false,
        };

        this.onSocialChange = this.onSocialChange.bind(this);
        this.onCityChange = this.onCityChange.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onClubChange = this.onClubChange.bind(this);
    }

    componentDidMount() {
        if (isMobile) {
            this.setState({ mobile: true });
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.name !== nextProps.name) {
            this.setState({ name: nextProps.name })
        }

        if (this.props.phone !== nextProps.phone) {
            this.setState({ phone: nextProps.phone })
        }

        if (this.props.city !== nextProps.city) {
            this.setState({ city: nextProps.city })
        }

        if (this.props.club !== nextProps.club) {
            this.setState({ club: nextProps.club })
        }

        if (this.props.social !== nextProps.social) {
            this.setState({ social: nextProps.social })
        }

        if (this.props.handle !== nextProps.handle) {
            this.setState({ handle: nextProps.handle })
        }

        if (this.props.promoLoading !== nextProps.promoLoading) {
            this.setState({ promoLoading: nextProps.promoLoading })
        }
    }

    onSocialChange(value) {
        this.props.promotionFieldChanged({ prop: "social", value });
    }

    onCityChange(value) {
        this.props.promotionFieldChanged({ prop: "city", value });
    }

    onClubChange(value) {
        this.props.promotionFieldChanged({ prop: "club", value });
    }

    onSave() {
        //extract state
        const { name, phone, city, social, handle, club } = this.state;

        this.props.sendPromotionRequest({ name, phone, city, social, handle, club });
    }

    renderBtn() {
        //extract state
        const { promoLoading } = this.state;

        if (promoLoading) {
            return <Spinner/>
        } else {
            return (
                <Button
                    text={"Send Request"}
                    onPress={() => this.onSave() }
                />
            );
        }
    }

    renderForm() {
        //extract state
        const { mobile } = this.state;

        if (mobile) {
            return(
                <div className={"promotion__box"}>
                    <Parallax
                        bgImage={featureGraphic}
                        strength={-200}
                        style={{ backgroundColor: "transparent", border: "none" }}
                    >
                        
                        <div className={"promotion__landing"}>
                            <div>
                                <h2>Win a brand new jersey</h2>
                            </div>
                        </div>

                        <div className={"promotion__form_box"}>
                            <div className={"text__input_box"}>
                                <Input
                                label={"Name"}
                                type={"text"}
                                onChange={(e) =>
                                    this.props.promotionFieldChanged({
                                    prop: "name",
                                    value: e.target.value,
                                    })
                                }
                                value={this.state.name}
                                />

                                <Input
                                label={"Phone"}
                                type={"text"}
                                onChange={(e) =>
                                    this.props.promotionFieldChanged({
                                    prop: "phone",
                                    value: e.target.value,
                                    })
                                }
                                value={this.state.phone}
                                />

                                <div>
                                <p>Your City</p>
                                <Select
                                    style={{ width: "90%" }}
                                    onChange={this.onCityChange}
                                    value={this.state.city}
                                >
                                    <Option value="dar">Dar</Option>
                                    <Option value="mwanza">Mwanza</Option>
                                    <Option value="mbeya">Mbeya</Option>
                                    <Option value="arusha">Arusha</Option>
                                    <Option value="zanzibar">Zanzibar</Option>
                                    <Option value="dodoma">Dodoma</Option>
                                    <Option value="iringa">Iringa</Option>
                                </Select>
                                </div>

                                <div>
                                    <p>Your Club</p>
                                    <Select style={{ width: "90%" }} onChange={this.onClubChange} value={this.state.club}>
                                        <Option value="azam">Azam</Option>
                                        <Option value="biasharaUnited">Biashara United</Option>
                                        <Option value="coastalUnited">Coastal United</Option>
                                        <Option value="dodomaJiji">Dodoma Jiji</Option>
                                        <Option value="geitaGold">Geita Gold</Option>
                                        <Option value="kageraSugar">Kagera SUgar</Option>
                                        <Option value="kmc">KMC</Option>
                                        <Option value="mbeyaCity">Mbeya City</Option>
                                        <Option value="mbeyaKwanza">Mbeya Kwanza</Option>
                                        <Option value="mtibwaSugar">Mtibwa Sugar</Option>
                                        <Option value="namungo">Namungo</Option>
                                        <Option value="polisiTanzania">Polisi Tanzania</Option>
                                        <Option value="ruvuShooting">Ruvu Shooting</Option>
                                        <Option value="simba">Simba</Option>
                                        <Option value="tanzaniaPrisons">Tanzania Prisons</Option>
                                        <Option value="yanga">Yanga</Option>
                                    </Select>
                                </div>

                                <div>
                                <p>Social Media</p>
                                <Select
                                    style={{ width: "90%" }}
                                    onChange={this.onSocialChange}
                                    value={this.state.social}
                                >
                                    <Option value="instagram">Instagram</Option>
                                    <Option value="twitter">Twitter</Option>
                                </Select>
                                </div>

                                <Input
                                label={"@Handle"}
                                type={"text"}
                                onChange={(e) =>
                                    this.props.promotionFieldChanged({
                                    prop: "handle",
                                    value: e.target.value,
                                    })
                                }
                                value={this.state.handle}
                                />
                                <div className={"button__box"}>{this.renderBtn()}</div>
                            </div>
                        </div>
                    </Parallax>
                </div>
            );
        } else {
            return (
                <div className={"viewOnMobile"}>
                  <h2>Kindly view this page in the LiveBall App</h2>
                </div>
              );
        }
    }

    render() {
        return(
            <div>
                { this.renderForm() }
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { name, phone, city, club, social, handle, promoLoading } = state.promotion;

    return {
        name,
        phone,
        city,
        social,
        club,
        handle,
        promoLoading
    }
}

export default connect(mapStateToProps, { promotionFieldChanged, sendPromotionRequest })(Promotion);