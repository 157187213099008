import React from "react";

class Prizes extends React.Component {
    render() {
        return (
            <div className={"fantacyPrizeContainer"}>
                <div>
                    <h1 id={"fantasyTitle"}>Prizes</h1>
                    <h2 id={"fantasyTitle"}>Overall Prizes</h2>
                    <h2 id={"fantasyTitle"}>1st Prize – 2021/22 Fantasy Premier League Champion</h2>
                    <ul>
                        <li>500,000 Tsh in cash prize</li>
                        <li>VIP hospitality at 2 NBC Premier League games</li>
                        <li>1 jerseys of your favourite teams and a liveball t-shirt</li>
                    </ul>
                </div>

                <div>
                    <h2 id={"fantasyTitle"}>Runner-up</h2>
                    <ul>
                        <li>250,000 Tsh in cash prize</li>
                        <li>VIP hospitality at 1 NBC Premier League game</li>
                        <li>1 jerseys of your favourite teams and a liveball t-shirt</li>
                    </ul>
                </div>

                <div>
                    <h2 id={"fantasyTitle"}>3rd place</h2>
                    <ul>
                        <li>VIP hospitality at 1 NBC Premier League game</li>
                        <li>Get liveball t-shirt</li>
                        <li>Wireless earphones</li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default Prizes;