import firebase from "firebase";

import {
    FETCH_PROMOTION_SUBMISSIONS, FETCH_PROMOTION_SUBMISSIONS_SUCCESSFUL,
    PROMOTION_FIELD_CHANGED,
    SEND_PROMOTION_REQUEST,
    SEND_PROMOTION_REQUEST_FAILED,
    SEND_PROMOTION_REQUEST_SUCCESSFUL
} from "./Types";

export const promotionFieldChanged = ({ prop, value }) => {
    return {
        type: PROMOTION_FIELD_CHANGED,
        payload: { prop, value}
    }
}

export const sendPromotionRequest = ({ name, phone, city, social, handle, club }) => {

    const requestID = Math.random().toString(36).substring(6).toUpperCase();
    const promotionID = "newJerseySept2021";
    const body = JSON.stringify({
        promotionID,
        requestID,
        name,
        phone,
        city,
        club,
        social,
        handle,
        path: `${promotionID}${requestID}`
    });

    return(dispatch) => {
        dispatch({ type:  SEND_PROMOTION_REQUEST });
        fetch("https://us-central1-liveballwa-23.cloudfunctions.net/requestPromotion",  {
            method: 'POST',
            body,
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
                console.log(response);

                dispatch({ type: SEND_PROMOTION_REQUEST_SUCCESSFUL });

            }).catch(function(error) {
            console.log("compute error");
            console.log(error);
            dispatch({ type:  SEND_PROMOTION_REQUEST_FAILED });
        });
    };
};


export const fetchPromotions = () => {
    return(dispatch) => {
        dispatch({ type: FETCH_PROMOTION_SUBMISSIONS });

        let promotions = [];

        firebase.firestore().collection("promotions")
            .onSnapshot(snapshot => {
                if (snapshot.size !== 0) {
                    snapshot.forEach(doc => {
                        const data = doc.data();
                        promotions.push(data);
                    });

                    dispatch({
                        type: FETCH_PROMOTION_SUBMISSIONS_SUCCESSFUL,
                        payload: promotions
                    });

                } else {
                    console.log("promotions path is empty");
                }
            }, function (e) {
                console.log(e);
            })
    };
}