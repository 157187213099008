import firebase from "firebase";

import {
    SPONSORSHIP_FIELD_CHANGED,
    SPONSOR_SAVING,
    SPONSOR_SAVED_SUCCESSFUL,
    SPONSOR_SAVED_FAILED,
} from "./Types";

export const sponsorshipChanged = ({ prop, value }) => {
    return {
        type: SPONSORSHIP_FIELD_CHANGED,
        payload: { prop, value}
    }
}

export const applyForSponsor = ({ companyName, companyAddress, companyContact, companyPhone, companyEmail, coreBusiness}) => {
    const sponsorID = Math.random().toString(36).substring(6).toUpperCase();

    return (dispatch) => {
        dispatch({type: SPONSOR_SAVING});
        firebase.firestore().collection("sponsorshipQueries").doc(sponsorID)
        .set({companyName, companyAddress, companyContact, companyPhone, companyEmail, coreBusiness, sponsorID}, {merge: true})
        .then(() => { dispatch({type: SPONSOR_SAVED_SUCCESSFUL}); console.log("succeed to save sponsor")})
        .catch((e) => { dispatch({type: SPONSOR_SAVED_FAILED}); console.log("failed to save sponsor"); console.log(e)})
    }
}