import React, { Component } from "react";
import { NavLink, BrowserRouter, Switch, Route } from "react-router-dom";
import { Button } from "../common/Button";
import { Link } from "react-scroll";
import { bubble as Menu } from "react-burger-menu";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isAndroid,
  isIOS,
} from "react-device-detect";

import { GrAppleAppStore } from "react-icons/gr";
import { IoLogoGooglePlaystore } from "react-icons/io5";

//-> component imports
import Home from "./Home/Home";
import Download from "./download/Download";
import Promotion from "./promotion/Promotion";
import ViewPromotions from "./promotion/ViewPromotions";
import About from "./about/About";
import Prizes from "./fantasy/Prizes";
import OfficialPodcast from "./fantasy/OfficialPodcast";
import Help from "./fantasy/Help";
import Rules from "./fantasy/Rules";

//-> file imports
import liveballLogo from "../assets/logo/logo.png";
import Footer from "../common/Footer";
import { AndroidBanner } from "../common/AndroidBanner";
import { IosBanner } from "../common/IosBanner";
import TermsAndConditions from "./fantasy/TermsAndConditions";
import ApplyForSponsorship from "./sponsorship/ApplyForSponsorship";



export class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuOpen: false,
      open: false,
    };
  }

  //handle state change for the mobile side menu
  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }
  //close the mobile side menu
  closeMenu() {
    this.setState({ menuOpen: false });
  }

  openAppleStore() {
    const appStoreUrl = "https://apps.apple.com/tz/app/liveball/id1580936245";
    return window.open(appStoreUrl, "_blank");
  }
  openPlayStore() {
    const playStoreUrl =
      "https://play.google.com/store/apps/details?id=com.firm23.liveball";
    return window.open(playStoreUrl, "_blank");
  }

  render() {
    return (
      <BrowserRouter>
        <div className={"appContainer"}>
          <div className={"navBarContainer"}>
            <BrowserView>
              <div className={"navBarBox"}>
                <div className={"navBarLogoBox"}>
                  <Link
                    onClick={this.handleScroll}
                    to="landing"
                    activeClass="active"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    <img src={liveballLogo} alt={"liveball logo"} />
                  </Link>
                </div>
                <nav>
                  <ul>
                    <li>
                      <Link
                        onClick={this.handleScroll}
                        to="features"
                        activeClass="active"
                        spy={true}
                        smooth={true}
                        duration={500}
                      >
                        Features
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={this.handleScroll}
                        to="screens"
                        activeClass="active"
                        spy={true}
                        smooth={true}
                        duration={500}
                      >
                        Live
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={this.handleScroll}
                        to="stats"
                        activeClass="active"
                        spy={true}
                        smooth={true}
                        duration={500}
                      >
                        Stats
                      </Link>
                    </li>
                    <li>
                      <NavLink to={"/sponsorship"}> Sponsorship </NavLink>
                    </li>
                    <li>
                      <Link
                        onClick={this.handleScroll}
                        to="footer"
                        activeClass="active"
                        spy={true}
                        smooth={true}
                        duration={500}
                      >
                        Contact
                      </Link>
                    </li>
                  </ul>

                  <div className={"downloadButtonBox"}>
                    <div className={"downloadApplePlayStore"}>
                      <div className={"downloadButton"}>
                        <GrAppleAppStore
                          size={"1.8em"}
                          color={'#fff8fe'}
                          onClick={() => this.openAppleStore()}
                        />
                      </div>
                      <div className={"downloadButton"}>
                        <IoLogoGooglePlaystore
                          size={"1.8em"}
                          color={'#fff8fe'}
                          onClick={() => this.openPlayStore()}
                        />
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
            </BrowserView>

            <MobileView>
              <Menu
                right
                width={"50%"}
                isOpen={this.state.menuOpen}
                onStateChange={(state) => this.handleStateChange(state)}
                noOverlay
              >
                <Link
                  onClick={this.handleScroll}
                  to="features"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <NavLink to={"/"} onClick={() => this.closeMenu()}>
                    Features
                  </NavLink>
                </Link>

                <Link
                  onClick={this.handleScroll}
                  to="screens"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <NavLink to={"/"} onClick={() => this.closeMenu()}>
                    Live
                  </NavLink>
                </Link>

                <Link
                  onClick={this.handleScroll}
                  to="stats"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <NavLink to={"/"} onClick={() => this.closeMenu()}>
                    Stats
                  </NavLink>
                </Link>

                <NavLink to={"/sponsorship"} onClick={() => this.closeMenu()}>
                  Sponsorship
                </NavLink>

                <Link
                  onClick={this.handleScroll}
                  to="footer"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <NavLink to={"/"} onClick={() => this.closeMenu()}>
                    Talk to us
                  </NavLink>
                </Link>
              </Menu>
            </MobileView>
            {isMobile ? (
              <div className={"navLogo"}>
                <Link
                  onClick={this.handleScroll}
                  to="landing"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <img src={liveballLogo} alt={"liveball logo"} />
                </Link>
              </div>
            ) : null}
          </div>

          {isMobile && isAndroid ? (
            <div>
              <AndroidBanner />
            </div>
          ) : isMobile && isIOS ? (
            <div>
              <IosBanner />
            </div>
          ) : null}

          <div className={"appBody"}>
            <Switch>
              <Route exact path={"/"} component={Home} />
              <Route exact path={"/download"} component={Download} />
              <Route exact path={"/promotion"} component={Promotion} />
              <Route exact path={"/viewPromotion"} component={ViewPromotions} />
              <Route exact path={"/about"} component={About} />
              <Route exact path={"/prizes"} component={Prizes} />
              <Route exact path={"/podcast"} component={OfficialPodcast} />
              <Route exact path={"/help"} component={Help} />
              <Route exact path={"/rules"} component={Rules} />
              <Route exact path={"/terms"} component={TermsAndConditions} />
              <Route exact path={"/sponsorship"} component={ApplyForSponsorship} />
            </Switch>
          </div>

          <div className={"footerBox"}>
            <Footer />
            {/*<NavLink to={"/viewPromotion"}><p>View</p></NavLink>*/}
          </div>
        </div>
      </BrowserRouter>
    );
  }
}

export default Main;
