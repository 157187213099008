import React from 'react';
import { Select } from 'antd';


export class SelectSponsorCoreTitleList extends React.Component {

    render() {
        const { Option } = Select;

        return (
            <div className={'sponsorship__form_box'}>
                 <div className={'text__select_box'}>
                    {
                        this.props.label ?
                            <p className={'inputLabelStyle'}>
                                {this.props.label}
                            </p> : null
                    }
                
                <Select
                    showSearch
                    style={{ width: 555 }}
                    className={"inputStyle"}
                    placeholder={this.props.placeholder}
                    optionFilterProp="children"
                    onChange={this.props.onChange}
                    value={this.props.value}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    <Option value={"artist"}>Artist</Option>
                    <Option value={"musicianOrBand"}>Musician/Band</Option>
                    <Option value={"blogger"}>Blogger</Option>
                    <Option value={"community"}>Community</Option>
                    <Option value={"digitalCreator"}>Digital Creator</Option>
                    <Option value={"education"}>Education </Option>
                    <Option value={"entrepreneur"}>Entrepreneur</Option>
                    <Option value={"betting"}>Betting</Option>
                    <Option value={"health"}>Health</Option>
                    <Option value={"restaurant"}>Restaurant</Option>
                    <Option value={"cosmetic"}>Cosmetic</Option>
                    <Option value={"photography"}>Photography</Option>
                    <Option value={"shoppingOrRetail"}>Shopping/Retail</Option>
                    <Option value={"agricultureAndFishing"}>Agriculture & Fishing</Option>
                    <Option value={"it"}>IT</Option>
                    <Option value={"nonProfit"}>Non-Profit</Option>

                </Select>
                </div>

            </div>
        );
    }
}

