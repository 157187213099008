import "./App.css";
import "./Responsive.css";
import Main from "./components/Main";
import React from "react";
import firebase from "firebase";
import {Helmet} from "react-helmet-async";
import Title from "antd/lib/skeleton/Title";
import SEO from "./components/SEO";

class App extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    const firebaseConfig = {
      //firebase api key
      apiKey: "AIzaSyC19P8Ou90QgDveldk_NMmNYiPjV-7buNE",
      authDomain: "liveballwa-23.firebaseapp.com",
      projectId: "liveballwa-23",
      storageBucket: "liveballwa-23.appspot.com",
      messagingSenderId: "904433458643",
      appId: "1:904433458643:web:6ca6a60b48d2aa4f6ae326",
      measurementId: "G-EZ7XZKBS0M",
    };

    //initialize firebase app
    firebase.initializeApp(firebaseConfig);
  }

  render() {
    return (
      <div className="app"> 
        <SEO />          
        <Main />        
      </div>
    );
  }
}

export default App;
