import React from 'react';
import { Collapse } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'

import 'antd/lib/collapse/style/css';

const { Panel } = Collapse;

class Help extends React.Component {

  render() {

    return (
      <div className={'rulesContainer'}>

        <div className={'rulesContainerBox'}>
          <h1>Help</h1>
        </div>

        <div className={'rulesQnBox'}>
          <Collapse
            accordion
            bordered={false}
            defaultActiveKey={['1']}
            expandIcon={({ isActive }) => isActive ? <FontAwesomeIcon icon={faMinus} size={"0.5x"} color={'ffffff'} /> : <FontAwesomeIcon icon={faPlus} size={"0.6x"} color={'ffffff'} />}
            className="collapseItem"
          >
            <Panel header="I can't signIn.What do I need to do?" key="1" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>Please ensure you are using your registered e-mail address and correct password. Note that the password is case sensitive.
                  If you’ve registered for the first time this season, you will need to activate your account before entering a Fantasy Premier League team. An email will be sent to the email address you’ve registered with and contains a link you’ll need to click in order to activate your account. If you haven’t received an email, please check your spam or junk mail folders.
                  Try resetting your password. A temporary password will be sent to your mailbox. Please update your password once logged in.
                  If you do not receive the password reminder, please check your Junk folder in your email inbox and add liveball@firm23.co.tz to your address book.
                </p>
              </div>
            </Panel>

            {/* <Panel header="I have set up my team, but I can't see my history or renew my leagues from previous season.Can you
            link up my new account to my old one?" key="2" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  Unfortunately, we cannot link your history from an old account to a new one. If you have set up a team for this season using a different email address, you will not be able to view your History from previous seasons on that account.
                  In order to see previous seasons' scores, you will need to sign in on the homepage using the registered email address and password combination from the previous seasons and set up a team using that account.
                </p>
              </div>
            </Panel> */}



            <h2 style={{ marginTop: "2rem" }}>Choose your initial squad</h2>

            <Panel header="Can I have more than one team ?" key="3" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  In the interest of fair play each person may only enter one team.
                </p>
              </div>
            </Panel>

            <Panel header="Can I change my squad after entering the game?" key="4" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  Yes, free transfers can be made before the next deadline.
                </p>
              </div>
            </Panel>

            <Panel header="Is there the print-friendly list of players can i view ?" key="5" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  Not yet made for now.
                </p>
              </div>
            </Panel>



            <h2 style={{ marginTop: "2rem" }}>Managing your team</h2>

            <Panel header="What formation can i play in?" key="6" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  You can play in any formation, providing 1 goalkeeper, at least 3 defenders and at least 1 forward are selected at all times.
                </p>
              </div>
            </Panel>

            <Panel header="How do i change my captain?" key="7" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  On the My Team page, use the menu which appears when clicking on a player.
                </p>
              </div>
            </Panel>

            <Panel header="How do i change my team name,my team kit or the team i support?" key="8" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  You can't change this information on the team.Unless inform admin to get this service.
                </p>
              </div>
            </Panel>

            <Panel header="Can i make changes to my team during Gameweek?" key="9" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  Any changes you make (starting 11, transfers, captain changes, substitution priorities) after the Gameweek deadline, will not take effect until the following deadline. View all deadlines.
                </p>
              </div>
            </Panel>



            <h2 style={{ marginTop: "2rem" }}>Offensive names</h2>

            <Panel header="How do i report an inappropiate or offensive name?" key="10" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  When registering a team or league within Liveball Fantasy Football, all managers are asked to think carefully before entering a name. Names that are deemed inappropriate or offensive may result in a team and/or league being deleted (see Terms & Conditions). If you spot a name you find inappropriate or offensive then please let us know by reporting the manager name and the team or league name using the form below. Your details will not be shared with anyone. We will look into the report and take immediate and appropriate action. It's important to us that Fantasy Premier League is an inclusive space where everyone feels welcome and safe. Thank you for your help.
                </p>
              </div>
            </Panel>



            <h2 style={{ marginTop: "2rem" }}>Chips</h2>

            <Panel header="How often can each chip be used?" key="11" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  The Bench Boost and Triple Captain chips can each be used once a season and are played when saving your team on the my team page. They can be cancelled at anytime before the Gameweek deadline.
                  The Free Hit chip can be used once a season, is played when confirming your transfers and can't be cancelled after confirmed.
                  The Wildcard chip can be used once a season and once in the second half of the season. The Wildcard chip is played when confirming transfers that cost points and can't be cancelled once played.
                </p>
              </div>
            </Panel>

            <Panel header="Can i use more than one chip in the same week?" key="12" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  No, only one chip may be active in a Gameweek. For example, it is not possible to make transfers with your Wildcard chip and then use your Bench Boost chip in the same Gameweek.
                </p>
              </div>
            </Panel>

            <Panel header="What happened to my Triple Captain chip if my captain doesn't play?" key="13" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  The triple points bonus will be passed to your vice-captain. If your vice-captain doesn't play either then the bonus is lost, the chip isn't returned.
                </p>
              </div>
            </Panel>

            <Panel header="How do prices changes affect Free Hit chip?" key="14" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  After playing your Free Hit chip, at the next deadline your bank balance and squad is restored to as they were at the previous deadline, with any player price changes in the previous gameweek reflected.
                  Any change to your bank balance whilst the Free Hit chip was active will be lost.
                </p>
              </div>
            </Panel>



            <h2 style={{ marginTop: "2rem" }}>Leagues</h2>

            {/* <Panel header="I can't join friend's invitational league and cup.What do i need to do?" key="15" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  First of all you must have entered a team in the game. Once you have a team, make sure that you have the correct league code and that you are entering it in the format it is written, for example a6x9zb.
                </p>
              </div>
            </Panel> */}

            {/* <Panel header="How do i renew my invitation league and cup?" key="16" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  If you were the league administrator, and your league was a Classic league, you will have the option to 'Renew your leagues' when you click on the 'Leagues' tab.
                  If your league was a Head-to-Head league, you will need to create a new league each season, as you are unable to renew this type of invitational league.
                </p>
              </div>
            </Panel> */}

            {/* <Panel header="I have been removed from one of my leagues.Why is this?" key="17" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  Either you removed yourself accidentally or you were removed from the league by the league administrator. Try rejoining the league using the league code. If you are unsuccessful, please check with your league administrator.
                </p>
              </div>
            </Panel> */}

            {/* <Panel header="How do i change my team name,my team kit or the team i support?" key="18" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  You can change this information on the team details page.
                </p>
              </div>
            </Panel> */}

            <Panel header="Why is my total is less than the sum of Gameweek points?" key="19" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  Transfer points are deducted from your total, as explained in the rules and before you confirm your transfers.
                </p>
              </div>
            </Panel>

            <Panel header="Why my Head-to-head score is less than the Gameweek score?" key="20" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  Transfer points are deducted from your Head-to-Head score, as explained in the rules.
                </p>
              </div>
            </Panel>

            <Panel header="How can i view other teams in my league?" key="21" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  Other teams can only be viewed after their first Gameweek has started, to allow managers to keep their team tactics secret.
                  To view a team, simply click on their team name in the league table.
                </p>
              </div>
            </Panel>

            {/* <Panel header="Is there a limit in number of teams in the invitation league?" key="22" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  No, the more the merrier. However, only 50 teams will be shown at once, so you will have to use the previous/next links to scroll through your league table.
                </p>
              </div>
            </Panel> */}

            <Panel header="I still haven't been added to my league?" key="23" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  Your team will be added to the league at the next points update.
                </p>
              </div>
            </Panel>

            <Panel header="I haven't been entered into supporters for my favourite club.What is this?" key="24" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  Please ensure that you have selected your favourite team.
                  As long as you select the club before your first deadline, you will be automatically entered into the supporters' league for that club.
                </p>
              </div>
            </Panel>

            <Panel header="Why am i not included in my country's leaderboard?" key="25" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  You are entered into the country league according to your liveball.io profile.
                  To update your country use the My Account page on liveball.io Your country league won't change after your first deadline even if you update your profile.
                </p>
              </div>
            </Panel>



            <h2 style={{ marginTop: "2rem" }}>Transfers</h2>

            <Panel header="If i don't use my free transfer,do i get two next Gameweek?" key="26" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  If you do not use your free transfer 2 only no addition, you are able to make an additional free transfer the following Gameweek. If you do not use this saved free transfer in the following Gameweek, it will be carried over until you do. You can never have more than 1 saved transfer.
                </p>
              </div>
            </Panel>

            <Panel header="Can i cancel my transfers?" key="27" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  No. Once you have confirmed your transfers, they are final and can’t be reversed under any circumstances.
                </p>
              </div>
            </Panel>

            <Panel header="I am only receiving the amount i paid for a player, not their current price minus sell- on fee?" key="28" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  Prices shown on the transfer page are players’ selling prices minus any sell-on fee. To check the price you paid for a player, please use the list view on the transfers page.
                </p>
              </div>
            </Panel>

            <Panel header="Tranfers don't seem to be costing me point?" key="29" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  Transfer points are deducted at the start of the next Gameweek.
                  Transfers are free of charge before the start of the season. If you join the game after the start of the season, transfers are free until your first deadline.
                </p>
              </div>
            </Panel>



            <h2 style={{ marginTop: "2rem" }}>Wildcards</h2>

            <Panel header="What is wildcard?" key="30" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  A wildcard allows you to make unlimited free transfers throughout a Gameweek. Playing a wildcard will remove any points deductions from transfers already made in the same Gameweek.
                  You play a wildcard when you want to make multiple changes to your team, for example Gameweeks when teams are playing more than once, or when your team is underperforming or has a lot of injuries.
                  When using a wildcard, you must remain within your current budget. There is no unlimited budget when using a wildcard.
                </p>
              </div>
            </Panel>

            <Panel header="When can I play wild card?" key="31" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  The Wildcard chip can be used once a season
                  Wildcards are played on the transfers page but aren't active until you have confirmed the transfers.
                </p>
              </div>
            </Panel>

            <Panel header="What happened to my saved transfer when i use my wildcard?" key="32" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  When playing a wildcard, any saved free transfers will be lost. You will be back to the usual 1 free transfer the following Gameweek.
                </p>
              </div>
            </Panel>

            <Panel header="Can I cancel my wildcard?" key="33" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  No. Once you have confirmed your wildcard it is final and can’t be reversed under any circumstances.
                </p>
              </div>
            </Panel>

            <Panel header="Do all transfers has to be at the same time when used my wildcard?" key="34" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  No. Once you have played your wildcard, any transfers you make within that Gameweek are free, including any you made before playing your wildcard, up until the next deadline.
                </p>
              </div>
            </Panel>



            <h2 style={{ marginTop: "2rem" }}>Gameweeks where the teams play no fixtures or play more than once</h2>

            <Panel header="What happened when team is playing twice in  one Gameweek?" key="35" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  Any players you have from the team playing twice will earn two sets of points, assuming that the player plays in both fixtures.
                  Any team captains playing in two fixtures will also earn double points for each match he plays in.
                </p>
              </div>
            </Panel>

            <Panel header="One of my player was scheduled to play twice in one Gameweek, but only played one match.Why wasn't he subtituted in the match he didn't play ?" key="36" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  If a player plays any minutes in any scheduled matches in a Gameweek, then he does not qualify for an automatic substitution.
                  The same rule applies to captains. If your captain only plays in one of the two matches, he will not be replaced as captain in the match he doesn’t play
                </p>
              </div>
            </Panel>

            <Panel header="What happens if a team doesn't have a fixture in a Gameweek?" key="37" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  Any players you have from that team will score 0 for that Gameweek. Players will play twice in a future Gameweek, when the match is rescheduled.
                  Points are only awarded to teams who have players selected in their team in the Gameweek the fixture is actually played, irrespective of if you had them selected for the original fixture.
                </p>
              </div>
            </Panel>


            <h2 style={{ marginTop: "2rem" }}>Player data</h2>
            <Panel header="When is the game updated?" key="38" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  Player points are updated as the matches take place, whilst team points and league tables are usually updated 2 hours after the final whistle in the last match of the day.
                  Bonus points will be awarded one hour after the final whistle of the last match of any given day.
                  Automatic substitutions and captain changes are processed at the end of the Gameweek, when all matches have been played.
                </p>
              </div>
            </Panel>

            <Panel header="A yellow/red card decision or goal keeper has been charged in an earlier match.Are you going to alter the match?" key="39" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  Once the points have all been updated on that day, no further adjustments to points will be made.
                </p>
              </div>
            </Panel>

            <Panel header="Why was/wasn't an assist given to...?" key="40" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  All assists are reviewed before the points are updated at the end of each day. If an assist still hasn’t been given after this time, please refer to the assist definition in the rules for further clarification.
                </p>
              </div>
            </Panel>

            <Panel header="How do player prices change?" key="41" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  Once the season has started, player prices may change by £0.1m a day, based on activity in the transfer market.
                  Both the formula used to calculate this change and the time of the change include variable factors, and won’t be revealed to game players.
                </p>
              </div>
            </Panel>

            <Panel header="How do you calculate form?" key="42" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  Form is a player’s average score per match, calculated from all matches played by his club in the last 30 days.
                </p>
              </div>
            </Panel>

            {/* <Panel header="What is the fixture Difficulty Ranking?" key="43" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  The Fixture Difficulty Ranking (FDR) is based on a complex algorithm developed by Fantasy Premier League experts. A set of formulas process key Opta data variables, along with each team’s home and away form (past six matches), in order to generate a rank for the perceived difficulty of each Gameweek opponent. The FDR is designed to help FPL managers plan their transfer and team selection strategy and is reviewed on a weekly basis.
                </p>
              </div>
            </Panel> */}

            {/* <Panel header="What is ICT index?" key="44" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  The ICT Index is a football statistical index developed specifically to assess a player as an FPL asset. It uses match event data to generate a single score for three key areas – Influence, Creativity and Threat. These figures then combine to create an individual’s ICT Index score. It condenses more than 40 match event statistics into four distinct scores. These offer a view on player performance for factors that are known to produce FPL points.
                  <ol type="1">
                    <li>Influence</li>
                    <p style={{ color: 'black' }}>Influence is the first measurement - this evaluates the degree to which that player has made an impact on a match, or matches over the season. It takes into account events and actions that could directly or indirectly effect the match outcome. At the very top level these are decisive actions like goals and assists. However, the Influence score also processes significant defensive actions to analyse the effectiveness of defenders and goalkeepers.</p>

                    <li>Creativity</li>
                    <p style={{ color: 'black' }}>Creativity assesses player performance in terms of producing goal scoring opportunities for others. It can be used as a guide to identify the players most likely to supply assists. While this analyses frequency of passing and crossing, it also considers pitch location and the incisiveness of the final ball.</p>

                    <li>Threat</li>
                    <p style={{ color: 'black' }}>Threat is the third measure, producing a value that examines a player’s threat on goal; it therefore gauges those individuals most likely to score goals. While attempts are the key action, the Index looks at pitch location, giving greater weight to actions that are regarded as the best openings to register a goal. All three of these scores are then combined to create an overall ICT Index score. That then offers a single figure that presents a view on that player as an FPL asset.</p>
                  </ol>
                </p>
              </div>
            </Panel> */}

            <Panel header="How to Liveball Fantansy decide which position a player should be categorised?" key="45" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  The players have been positioned in the game based on the following position definitions:
                  <ul>
                    <li>Goalkeeper</li>
                    <p style={{ color: 'black' }}>The player in goal who is permitted to use their hands inside the penalty area.
                      It is worth noting that if an outfield player takes over goalkeeping responsibility (due to no substitute goalkeeper being available), he will be able to collect points for saves, but will score points as per their position classification (e.g. if a midfielder assumes goalkeeping responsibilities, he will only receive one point for a clean sheet) in the game.</p>

                    <li>Defender</li>
                    <p style={{ color: 'black' }}>Lining up in front of the goalkeeper, these players are charged with defending their goal.
                      Central defenders will often be dominant figures, amassing clearances, blocks and interceptions (CBI).
                      Full-backs and wing-backs will often record more tackles while also contributing in attack. They provide crosses and create chances for team-mates.</p>

                    <li>Midfielder</li>
                    <p style={{ color: 'black' }}>A player positioned in front of the defence who either plays centrally or in a wide position.
                      They are charged with breaking up opponents’ attacks and providing the passes and crosses to create chances for their own team.
                      This position also includes attacking midfielders who can play behind the forward line as a No 10, or as wide players and wingers and therefore includes players on the left and right of a central striker in a 4-3-3 formation. Their role is to provide goals, either by creating chances or by converting opportunities themselves.</p>

                    <li>Forward</li>
                    <p style={{ color: 'black' }}>The forward (also known as a striker) is deployed ahead of the midfield and in a central area rather than out wide.
                      They often operate either as a lone striker or as part of a front pairing, e.g. a 4-4-2 or 3-5-2 formation.
                      Their primary role is to score goals rather than create chances, and this is reflected in the Bonus Points System (BPS) with forwards rewarded more significantly for goals scored.</p>
                  </ul>
                </p>
              </div>
            </Panel>



            {/* <h2 style={{ marginTop: "2rem" }}>Bonus Points</h2> */}

            {/* <Panel header="How are bonus points calculated?" key="46" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  The three best performing players in each match according to the Bonus Points System (BPS) will receive additional bonus points. 3 points will be awarded to the highest scoring player, 2 to the second best and 1 to the third.
                  Examples of how bonus point ties will be resolved are as follows:
                  <ul>
                    <li>If there is a tie for first place, Players 1 & 2 will receive 3 points each and Player 3 will receive 1 point.</li>
                    <li>If there is a tie for second place, Player 1 will receive 3 points and Players 2 and 3 will receive 2 points each.</li>
                    <li>If there is a tie for third place, Player 1 will receive 3 points, Player 2 will receive 2 points and Players 3 & 4 will receive 1 point each.</li>
                  </ul>
                </p>
              </div>
            </Panel>

            <Panel header="When are bonus point is added?" key="47" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  Bonus points will be awarded one hour after the final whistle of the last match of any given day.
                </p>
              </div>
            </Panel>

            <Panel header="My player's BPS has changed since the end of his match.Why?" key="48" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  Some BPS statistics are received after the end of the match so players may rise / fall up to an hour after the end of the days matches.
                </p>
              </div>
            </Panel> */}


            <h2 style={{ marginTop: "2rem" }}>Automatic substitution</h2>
            <Panel header="Why haven't the players been automatically substitued yet?" key="49" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  Substitutions happen at the end of the Gameweek, when all matches have been played. You can check the list of current fixtures, to determine when this is.
                </p>
              </div>
            </Panel>

            <Panel header="I think my automatic substitutions are wrong." key="50" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  Your team must have 1 goalkeeper, at least 3 defenders and at least 1 forward selected at all times.
                  If your starting team has 3 defenders, and one of them played 0 minutes, he can only be replaced by another defender who played.
                </p>
              </div>
            </Panel>


            <h2 style={{ marginTop: "2rem" }}>Tanzania League transfers</h2>
            <Panel header="What happened when a player is transfered to another Tanzania Premium League?" key="51" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  If a player is transferred to another team in the Premier League, and this transfer takes you over the 3 players per team limit, then you will need to go back under the limit when making your next transfers.
                </p>
              </div>
            </Panel>

            <Panel header="When will a new players be added?" key="52" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  New players will be added within 7 days of their transfer being completed.
                </p>
              </div>
            </Panel>

            <Panel header="My player has left Tanzania Premiun League.What do I do now?" key="53" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>
                  You can leave the player in your squad, where he will continue to earn 0 points and automatically disable a player to earn any point. To remove him permanently, you must use a transfer.
                </p>
              </div>
            </Panel>

          </Collapse>
        </div>

      </div>
    )
  }
};


export default Help;