import React from 'react';
import { connect } from 'react-redux';
import {message} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//Action imports
import { sponsorshipChanged, applyForSponsor } from "../../actions/SponsorshipActions";


//component imports
import {Input} from "../../common/Input";
import {Button} from "../../common/Button";
import {Spinner} from "../../common/Spinner";
import { SelectSponsorCoreTitleList } from '../../common/SelectSponsorCoreTitleList';

class ApplyForSponsorship extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            companyName: this.props.companyName,
            companyAddress: this.props.companyAddress,
            companyContact: this.props.companyContact,
            companyPhone: this.props.companyPhone,
            companyEmail: this.props.companyEmail,
            sponsorLoading: this.props.sponsorLoading,
            coreBusiness: this.props.coreBusiness,
        };

        this.onSelectSponsorList = this.onSelectSponsorList.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContent) {

        if (nextProps.companyName !== this.props.companyName) {
            this.setState({ companyName: nextProps.companyName});
        }
        if (nextProps.companyAddress !== this.props.companyAddress) {
            this.setState({ companyAddress: nextProps.companyAddress});
        }
        if (nextProps.companyContact !== this.props.companyContact) {
            this.setState({ companyContact: nextProps.companyContact});
        }
        if (nextProps.companyPhone !== this.props.companyPhone) {
            this.setState({ companyPhone: nextProps.companyPhone});
        }
        if (nextProps.companyEmail !== this.props.companyEmail) {
            this.setState({ companyEmail: nextProps.companyEmail});
        }
        if (nextProps.coreBusiness !== this.props.coreBusiness) {
            this.setState({ coreBusiness: nextProps.coreBusiness});
        }
        if (nextProps.sponsorLoading !== this.props.sponsorLoading) {
            this.setState({ sponsorLoading: nextProps.sponsorLoading});
        }




    }

    onSelectSponsorList(value){
        this.props.sponsorshipChanged({ prop: 'coreBusiness', value })
    }

    onApplyPress() {
        //handle apply
        const { companyName, companyAddress, companyContact, companyPhone, companyEmail, coreBusiness } = this.state;

        //check if fields are not empty
        if (companyName && companyAddress && companyContact && companyPhone && companyEmail && coreBusiness) {
            this.props.applyForSponsor({ companyName, companyAddress, companyContact, companyPhone, companyEmail, coreBusiness});
        } else {
            message.warning('Fill all required fields');
        }
    }

    renderButton() {

        if (this.state.sponsorLoading) {
            return(
                <div>
                    <Spinner/>
                </div>
            );
        } else {
            return (
                <div>
                    <Button
                        text={'Apply'}
                        onPress={() => this.onApplyPress() }
                    />
                </div>
            );
        }
    }


    render() {
        return (
            <div className={"promotion__form_box"}>
                <h2>Apply for Sponsorship</h2>
                <div className={"text__input_box"}>
                    <Input
                    label={"Company Name"}
                    type={"text"}
                    onChange={(e) => this.props.sponsorshipChanged({prop: "companyName", value: e.target.value,})}
                    value={this.state.companyName}
                    />

                    <Input 
                    label={"Company Address"}
                    type={"text"}
                    onChange={(e) => this.props.sponsorshipChanged({prop: "companyAddress", value: e.target.value,})}
                    value={this.state.companyAddress}
                    />

                    <SelectSponsorCoreTitleList
                    label={"Core Business"}
                    onChange={this.onSelectSponsorList}
                    value={this.state.coreBusiness}
                    />

                    <Input 
                    label={"Company Contact"}
                    type={"text"}
                    onChange={(e) => this.props.sponsorshipChanged({prop: "companyContact", value: e.target.value,})}
                    value={this.state.companyContact}
                    />

                    <Input 
                    label={"Company Phone"}
                    type={"number"}
                    onChange={(e) => this.props.sponsorshipChanged({prop: "companyPhone", value: e.target.value,})}
                    value={this.state.companyPhone}
                    />

                    <Input 
                    label={"Company E-mail"}
                    type={"text"}
                    onChange={(e) => this.props.sponsorshipChanged({prop: "companyEmail", value: e.target.value,})}
                    value={this.state.companyEmail}
                    />

                    <div className={"button__box"}>{this.renderButton()}</div>
                    
                </div>
            </div>
        );
    }
}

//call method to transform redux state to component props
const mapStateToProps = (state) => {

    //fetch state properties from specific reducer
    const { companyName, companyAddress, companyContact, companyPhone, companyEmail, sponsorLoading, coreBusiness} = state.sponsorships;

    //return object with state properties
    return {
        companyName,
        companyAddress,
        companyContact,
        companyPhone,
        companyEmail,
        sponsorLoading,
        coreBusiness
    }
};

export default connect(mapStateToProps, { sponsorshipChanged, applyForSponsor })(ApplyForSponsorship);