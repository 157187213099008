import React from "react";

class About extends React.Component {
    render() {
        return(
            <div className={"about"}>
                <h1>About LiveBall</h1>

                <section>
                    <h1>
                        LiveBall is a sports data digital system developed for the Tanzania Premier League that brings
                        together a sophisticated computer engineered panel & a simple football mobile app.
                    </h1>
                </section>

                <section>
                    <p>
                        After collaboration with TPL, LiveBall collected a chunk of previous
                        seasons data for analysis. However, the complete LiveBall revolution starts with the new upcoming season 2021-22 in
                        which during and after Live Premier League Matches, in-depth data is set to be collected by Data Collectors & Analysts using a
                        computer engineered panel and then harvested to develop insights based on play-by-play analysis of the games and
                        player performances such as:
                    </p>
                </section>

                <section>
                    <p>
                        Shots on Target, Shots Off Target, Hit Woodwork, Offsides, Touches, Chances Created, Big Chances Missed,
                        Fouls Drawn, Key Passes, Back Passes, Progressive Passes, Side Passes, Long Ball Passes, Through Balls,
                        Successful Dribbles, Unsuccessful Dribbles, Successful Take Ons, Unsuccessful Take Ons, Successful Crosses,
                        Unsuccessful Crosses, Successful Corners, Unsuccessful Corners, Clean Sheets, Won Tackles, Lost Tackles,
                        Last Man Tackles, Blocks, Interceptions, Clearances, Headed Clearances, Clearances Off-line, Recoveries,
                        Duels won/lost, Aerial battles won/lost, Fouls Committed, Errors leading to goal, Penalties conceded,
                        Saves, penalties saved, punches, high claims, catches, sweeper clearances, throw outs, goal kicks, etc.
                    </p>
                </section>

                <section>
                    <p>
                        These insights will add immense value to our league, fundamental in player development to know vital
                        areas of improvement, insights that can be used by player and club in contract negotiations, in scouting
                        and transfers to know which player or club are compatible together in the style of play, match predictions
                        by sports journalists & betting companies, virtual game development (FIFA, PES) the possibilities are vast
                        and they all lead to new direct revenue streams for clubs, players and companies. But most importantly,
                        since deployment and launch, LiveBall has been of huge interest to football fans.
                    </p>
                </section>

                <section>
                    <p>
                        Through the mobile app, fans get to get live updates of their favorite TPL teams. Live Scores as they
                        go in — every match, the play-by-play analysis of each player to know how they performed, after all,
                        football is a game of history & numbers
                    </p>
                </section>
            </div>
        );
    }
}

export default About;