//promotion types
export const PROMOTION_FIELD_CHANGED = "promotion_field_changed";
export const SEND_PROMOTION_REQUEST = "send_promotion_request";
export const SEND_PROMOTION_REQUEST_SUCCESSFUL = "send_promotion_request_successful";
export const SEND_PROMOTION_REQUEST_FAILED = "send_promotion_request_failed";

export const FETCH_PROMOTION_SUBMISSIONS = "fetch_promotion_submissions";
export const FETCH_PROMOTION_SUBMISSIONS_SUCCESSFUL = "fetch_promotion_submissions_successful";
export const FETCH_PROMOTION_SUBMISSIONS_FAILED = "fetch_promotion_submissions_failed";

export const SPONSORSHIP_FIELD_CHANGED = "sponsorship_field_changed";
export const SPONSOR_SAVING = "sponsor_saving";
export const SPONSOR_SAVED_SUCCESSFUL = "sponsor_saved_successful";
export const SPONSOR_SAVED_FAILED = "sponsor_saved_failed";