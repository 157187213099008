import React, { Component } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import { isAndroid, isIOS, isMobile } from "react-device-detect";
import moment from "moment";

//-> file imports
import liveballLogo from "../assets/logo/logo.png";

//-> icon imports
import { GrAppleAppStore } from "react-icons/gr";
import { IoLogoGooglePlaystore } from "react-icons/io5";

//-> react icons imports
import {
  IoLogoFacebook,
  IoLogoInstagram,
  IoChevronUpCircleOutline,
} from "react-icons/io5";
import { AiFillTwitterCircle } from "react-icons/ai";
import { GiSoccerField } from "react-icons/gi";

export class Footer extends Component {
  scrollToTop() {
    scroll.scrollToTop();
  }

  openAppleStore() {
    const appStoreUrl = "https://apps.apple.com/tz/app/liveball/id1580936245";
    return window.open(appStoreUrl, "_blank");
  }
  openPlayStore() {
    const playStoreUrl =
      "https://play.google.com/store/apps/details?id=com.firm23.liveball";
    return window.open(playStoreUrl, "_blank");
  }

  goToTwitter() {
    const twitterUrl = "https://twitter.com/liveballio?s=21";
    return window.open(twitterUrl, "_blank");
  }
  goToInstagram() {
    const instaUrl = "https://instagram.com/liveball.io?utm_medium=copy_link";
    return window.open(instaUrl, "_blank");
  }
  goToFacebook() {
    const facebookUrl = "https://www.facebook.com/Firm-23-714599578933702/";
    return window.open(facebookUrl, "_blank");
  }

  renderYear(){
    const year = moment().format("YYYY");
    return <p>© {year} Firm 23 Limited</p>
  }

  render() {
    return (
      <footer id={"footer"}>
        <div>
          <div>
            <img src={liveballLogo} alt={"liveball logo"} />
          </div>
          <h4>Liveball</h4>
        </div>

        <div>
          <div>
            <AiFillTwitterCircle
              className={"socialIcon"}
              onClick={() => this.goToTwitter()}
            />
          </div>
          <div>
            <IoLogoInstagram
              className={"socialIcon"}
              onClick={() => this.goToInstagram()}
            />
          </div>
          <div>
            <IoLogoFacebook
              className={"socialIcon"}
              onClick={() => this.goToFacebook()}
            />
          </div>
        </div>

        <div>
          <div>
            <Link
              onClick={this.handleScroll}
              to="features"
              activeClass="active"
              spy={true}
              smooth={true}
              duration={500}
            >
              Features
            </Link>
          </div>
          {/* <div>
            <Link
              onClick={this.handleScroll}
              to="landing"
              activeClass="active"
              spy={true}
              smooth={true}
              duration={500}
            >
              Events
            </Link>
          </div> */}
          <div>
            <IoChevronUpCircleOutline
              className={"chevronScrollUpIcon"}
              onClick={this.scrollToTop}
            />
          </div>
        </div>

        {!isMobile && isAndroid ? (
          <div className={"store__links"}>
            <div onClick={() => this.openAppleStore()}>
              <div>
                <GrAppleAppStore className={"storeIcon"} />
              </div>
              <div>
                <p>Download on the</p>
                <h4>App Store</h4>
              </div>
            </div>

            <div onClick={() => this.openPlayStore()}>
              <div>
                <IoLogoGooglePlaystore className={"storeIcon"} />
              </div>
              <div>
                <p>Get it on</p>
                <h4>Google Play</h4>
              </div>
            </div>
          </div>
        ) : null}

        {isMobile && isAndroid ? (
          <div className={"store__link"}>
            <div onClick={() => this.openPlayStore()}>
              <div>
                <IoLogoGooglePlaystore className={"storeIcon"} />
              </div>
              <div>
                <p>Get it on</p>
                <h4>Google Play</h4>
              </div>
            </div>
          </div>
        ) : isMobile && isIOS ? (
          <div className={"store__link"}>
            <div onClick={() => this.openAppleStore()}>
              <div>
                <GrAppleAppStore className={"storeIcon"} />
              </div>
              <div>
                <p>Download on the</p>
                <h4>App Store</h4>
              </div>
            </div>
          </div>
        ) : null}

        <div className={"copyright"}>
          {this.renderYear()}
        </div>
      </footer>
    );
  }
}

export default Footer;
