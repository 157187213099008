import React, { Component } from 'react'

export class TermsAndConditions extends Component {
  render() {
    return (
      <div className={"fantasyTermsAndConditionsContainer"}>
        <h3>Fantasy Football Terms and Conditions</h3>

        <p>
            In these Terms of Use, any use of the words "you", "yours" or similar expressions shall mean any user of this website whatsoever.
            Terms such as "we", "us, "our" or similar expressions shall mean Liveball.
        </p>

        <h4>
            2022/23 Fantasy Football – Terms & Conditions
        </h4>
        <p>
            By registering to participate in the Fantasy Football (the "Game") you agree to be bound by and to abide by the following terms and conditions (the "Terms").
        </p>
        <p>
            These Terms may be amended or modified, or new conditions may be imposed, at any time. Any such changes or additions will be reflected by an update of this page.
            Please check these Terms periodically for changes. Your continued use of the Game following the posting of changes to these Terms will mean you accept those changes
            and that such changes shall apply to your use of the Game after such changes have been posted.
        </p>
        <p>
            The promoter of the Game is Firm 23 Limited, a company registered in the United Republic of Tanzania under company number 138810518 with the registered office at Mbezi Beach, Rainbow,
            Ally Sykes Road, ("we", "our" or  "Liveball").
        </p>
        <h4>Your use of the Game if you are under 18</h4>
        <p>
            If you are under 18, you may need your parent/guardian to help you with your use of the Game and with reading these Terms. If anything is hard to understand,
            please ask your parent/guardian to explain. If you still have any questions, you or your parent/guardian can contact us at: liveball@firm23.co.tz.
        </p>
        <p>
            If you are under 13, you cannot register for an account without the permission of your parent/guardian. You need to register if you want to play the Game.
        </p>
        <p>
            If you are under the age of 18, you will also not be eligible to win any cash element of a Prize (if applicable) and parental or guardian permission must be obtained to claim
            any Prize. Such permission must include confirmation that the parent/guardian agrees to the Player's Entry to the Game, they agree to the Player being awarded a Prize and
            will accompany the Player during any travel connected to the Prize. For the avoidance of doubt, this permission is separate to the permission that must be obtained by
            all Players under the age of 13 to set up a Liveball account, which is required to play the Game.
        </p>
        <p>
            We may collect your personal information when you use the Game. You can find out more about how we use your personal information in our Questions and Answers.
            You can find a link here: https://www.firm23.co.tz/liveball-privacy-policy.
        </p>
        <h4>Applications and Registration</h4>
        <p>
            1. All applications to participate in the Game must be made via the Liveball mobile application (the "App").
            No applications made in any other manner will be accepted. Where your application is accepted, you have successfully "Registered" and will become a "Player" of the Game.
            Each Player is then able to create a team.
        </p>
        <p>
            2. Registration for the Game is open to both Tanzanian and non Tanzanian residents. Employees (and immediate family members) of Firm 23 Limited (‘Liveball")
            may enter the Game but are ineligible to win any Prizes (as defined below). If such person would otherwise win a Prize, the Prize shall be awarded to the next placed Player.
        </p>
        <p>
            3. Under 18s may Register to play the Game, however the permission of a parent or guardian is required to receive a Prize.
            The eligibility of an under 18 to win a Prize is set out below.
        </p>
        <p>
            4. No purchase of any item or service is necessary in order to enter the Game and no payment is required from you.
        </p>
        <p>
            5. Registrations must not be made by agents or third parties. No responsibility can be accepted for lost, delayed or incomplete Registrations or Registrations not received
            by Liveball for any reason. Any such Registrations will be deemed void.
        </p>
        <p>
            6. There is no limit on the number of Registrations per household or organization. However, only one Registration for the Game per user of the App is permitted.
            Individuals are not permitted to register multiple accounts on the App.
        </p>
        <p>
            7. While there is no closing date for Registration, and applications can be made throughout the NBC Premier League season, Liveball will publish deadlines from time to time
            by which you must be Registered as a Player in order to qualify for points awarded in respect of any round of matches in the NBC Premier League competition.
            Any such information on how and when to enter for the Game forms part of these Terms and shall be binding on you.
        </p>
        <p>
            8. Liveball wishes to ensure the Game is an inclusive and safe place for all Players. Liveball reserves the right to change the name of a Registration,
            team or suspend and/or delete Registrations, teams that contain names, words or phrases which are deemed solely by Liveball to be inappropriate, promotional, profane,
            derogatory, discriminatory, divisive or offensive (irrespective of the opinion of the Player).
        </p>
        <p>
            9. In submitting a Registration and becoming a Player, you will be providing personal data to Liveball. Any personal data which you do submit will be processed in accordance
            with Liveball's Privacy Policy, which can be found here: https://www.firm23.co.tz/liveball-privacy-policy and in accordance with relevant data protection legislation
            including the General Data Protection Regulation ("GDPR") and the Data Protection Act 2018. If you are under 18, you can find out more about how we use your personal
            information in our Questions and Answers, which is available here: https://www.firm23.co.tz/liveball-privacy-policy
        </p>
        <p>
            10. We will only share your data in accordance with our Privacy Policy and as set out in these Terms. Where a Player is a winner of a Prize, the winner's name may be
            published on the Site or App in accordance with clauses 27 and 28. Liveball may also share your personal data if required to do so by a competent authority or court within
            the United Republic of Tanzania.
        </p>
        <h4>The Game Rules</h4>
        <p>
            11. The Game will be governed by the Game Rules (as amended from time to time) published on the App by Liveball ("Game Rules"). Liveball reserves the right to alter,
            amend or supplement the Game Rules in its absolute discretion. You agree that no liability shall attach to Liveball as a result of any such change and so are advised to check
            the Game Rules regularly. The Game Rules form part of these Terms and by Registering for the Game, you accept the Game Rules.
        </p>
        <p>
            12. Liveball will be the sole decision-maker of any matter of interpretation of the Game Rules and any aspect of the content of, or playing of, the Game. Liveball will not
            enter into correspondence relating to such matters and its decision as to any matter arising out of or in connection with the Game Rules including but not limited to the
            allocation of points to any Player and/or the award of any Prize and/or any ranking or league table shall be final and conclusive.
        </p>
        <h4>Prizes and Winners Details</h4>
        <p>
            13. Liveball may choose to award prizes in respect of the Game ("Prizes"). Such Prizes will be available at the discretion of Liveball and in accordance with criteria set by it.
            Cash prizes may be awarded in installments. Liveball reserves the right to alter and amend the Prizes or the criteria for winning a Prize, where circumstances beyond its
            reasonable control require it to do so.
        </p>
        <p>
            14. Prizes are not transferable and are non-exchangeable. Liveball will make reasonable endeavors to accommodate any needs or requirements of Prize recipients and where a
            Prize includes travel or attendance at an event, Liveball will seek to agree dates with the recipient. However, where the recipient is unavailable on the dates provided by
            Liveball, the Prize may be forfeited, or another recipient selected. If any aspect of a Prize, is cancelled or postponed due to circumstances beyond Liveball’s control,
            including but not limited to, travel cancellations, delays or interruptions due to acts of God, acts and advice of Governments, acts of war, epidemic, pandemic, natural disasters,
            weather, acts of terrorism, national catastrophes, advice against any travel by any Government or official authority for any reason (including by way of example only and without
            limitation by reason of any actual or potential terrorist threat) and/or the World Health Organisation or any internationally recognised medical authority for any reason or an
            event of force majeure, Liveball shall not be responsible and that portion of the Prize will not be awarded and no additional compensation will be provided. No refunds or credit
            for changes to Prizes are allowed.
        </p>
        <p>
            15. Subject to compliance with these Terms, all Players (excluding those listed in clause 2) are eligible to win the Prizes. However, where a Player is under the age of 18, t
            hey will not be eligible to win any cash element of a Prize (if applicable) and parental or guardian permission must be obtained. Such permission must include confirmation that
            the parent/guardian agrees to the Player's Entry to the Game, they agree to publication of the Player’s name as winner of the Prize, they agree to the Player being awarded a
            Prize and will accompany the Player during any travel connected to the Prize. For the avoidance of doubt, this permission is separate to the permission that must be obtained by
            all Players under the age of 13 to set up a Liveball account, which is required to play the Game.
        </p>
        <p>
            16. Liveball intends (but does not undertake) for the following Prizes to be available:
        </p>
        <p>
            17. The first Prize ("Winner's Prize") will be awarded to the Player who, according to Liveball's determination, is at the top of the leaderboard published by Liveball at the end of
            the 2022/23 season.
        </p>
        <p>
            a) The Winner's Prize will be Tshs 500,000 in cash prize(the identity of which is determined by Liveball) VIP hospitality at 2 NBC Premier league games and 1 jerseys of your favourite teams and a liveball t-shirt. The precise specification and nature of these items shall be at the
            discretion of Liveball.
        </p>
        <p>
            18. The second Prize ("Runner-up Prize") will be awarded to the Player who according to Liveball's determination finishes in second place in the overall leaderboard published by
            Liveball at the end of the 2022/23 season.
        </p>
        <p>
            a) The Runner-up Prize includes Tshs 250,000 in cash(the identity of which is determined by Liveball). The prize includes VIP hospitality at 1 NBC Premier league game.
        </p>
        <p>
            b) The Runner-up's Prize is also likely to include 1 jersey of a favorite team and a livebalt-shirt. The precise specification and nature of these items shall
            be at the discretion of Liveball.
        </p>
        <p>
            19. The third Prize ("Third Prize") will be awarded to the Player who according to Liveball's determination finishes in third place in the overall leaderboard published by
            Liveball at the end of the 2022/23 season.
        </p>
        <p>
            a) Subject to availability and any unforeseen changes, the Third Prize shall consist of VIP hospitality at 1 NBC Premier League game, wireless earphones and a liveball t-shirt.
        </p>
        <p>
            20. In the event:
        </p>
        <p>
            21. The "Cup Prize" will be awarded to the Player who, according to Liveball's determination, wins the cup final at the end of the season.
        </p>
        <p>
            a) The Winner's Prize will be Tshs 500,000 in cash prize(the identity of which is determined by Liveball) VIP hospitality at 2 NBC Premier league games and 1 jerseys of your favourite teams and a liveball t-shirt. The precise specification and nature of these items shall be at the
            discretion of Liveball.
        </p>
        <p>
            22. Winners will be notified of their success by email in respect of the Prizes, using the email address used when Registering. In respect of the Winner/Runner-up/Third/Cup Prize by
            Sunday 9th June 2023 at which time winners must confirm their contact details prior to the Prize in question being delivered.
        </p>
        <p>
            23. The winners' names will be published on the Site (subject to paragraph 15 and paragraph 24 of these Terms) by the 28th day of the month following the month in respect of which the
            Monthly Prize is awarded in respect of winners of Monthly Prizes.
        </p>
        <p>
            24. You hereby consent to the use by Liveball of your name or team names for future promotional, marketing and publicity purposes of Liveball or the Game without any compensation or
            prior notice. If you have an objection to the use of your name or team, please contact liveball@firm23.co.tz</p>
        <p>
            25. Liveball may wish to use the name and photograph of winners for promotional, marketing and publicity purposes in any and all media worldwide. Liveball will contact winners to
            request their consent to their image being used. Where a Player is under 18, parental consent will also be required.
        </p>
        <h4>Player Responsibilities</h4>
        <p>
            26. You warrant and agree that, while participating in the Game, you shall not upload, post or transmit to or distribute or otherwise publish through the Game or in relation to the Game
            any information, content or materials that:
        </p>
        <p>(a) are protected by copyright, or other proprietary or intellectual property right, or derivative works with respect thereto, except as provided herein or without first obtaining permission from us or the copyright owner;</p>
        <p>(b) are unlawful, threatening, harassing, profane, tortious, defamatory, discriminatory, vulgar, obscene, libellous, deceptive, fraudulent, contains explicit or graphic descriptions or accounts of sexual acts (including, but not limited to, sexual language of a violent or threatening nature directed at another individual or group of individuals), invasive of another's privacy, or hateful;</p>
        <p>(c) restrict or inhibit any other user from using and enjoying the Game;</p>
        <p>(d) constitute or encourage conduct that would constitute a criminal offense or give rise to civil liability; or</p>
        <p>(e) contain a virus or other harmful component or similar computer code designed to adversely affect the operation of any computer software or hardware, advertising of any kind, or false or misleading indications of origin or statements of fact.</p>
        <p>
            27. You also warrant and agree that you shall not:
        </p>
        <p>(a) impersonate, or misrepresent your affiliation with any other person or entity;</p>
        <p>(b) upload, post, publish, transmit, reproduce, distribute or in any way exploit any information or other material obtained through the Game for commercial purposes (other than as expressly permitted by the provider of such information or other material);</p>
        <p>(c) engage in spamming or flooding;</p>
        <p>(d) use automated systems to access the Game and extract information from the Game;</p>
        <p>(e) attempt to gain unauthorized access to other computer systems through the Game; or</p>
        <p>(f) bring the Game or Liveball into disrepute.</p>
        <p>
            28. Except as otherwise expressly permitted herein, you may not upload, post, publish, reproduce, transmit or distribute in any way any component of the Game itself or derivative
            works with respect thereto, as the Game is copyrighted as a collective work under the copyright laws of United Republic of Tanzania
        </p>
        <h4>Liveball Responsibility and Liability</h4>
        <p>
            29. The extent of Liveball's responsibility to you has been determined in the context of the following:
        </p>
        <p>a) the Game is provided to you free of charge and on an "as is" basis;</p>
        <p>b) you are responsible for any action you do or do not take as a result of the Game and the information therein; and</p>
        <p>c) you are responsible for ensuring that your equipment is enabled with appropriate up-to-date virus checking software.</p>
        <p>
            30. While Liveball will endeavor to ensure that the Game is available to you at all times, without faults and that the contents are correct and accurate, it cannot make any legal
            commitment to you that this will be the case.
        </p>
        <p>
            31. Liveball reserves the right at any time to temporarily or permanently modify or discontinue the Game with or without prior notice (due to reasons outside its reasonable control).
            In the event that Liveball permanently discontinues the Game pursuant to this paragraph, you shall be released from your obligations to Liveball under these Terms.
        </p>
        <p>
            32. Nothing in these Terms limits or excludes Liveball's liability for (i) death or personal injury caused by our negligence; (ii) liability arising from fraud or fraudulent
            misrepresentation; or (iii) any other reason or by any cause which cannot be excluded by law.
        </p>
        <p>
            33. Liveball accepts no liability to you for any damage, loss, liabilities, injury or disappointment incurred or suffered by you as a result of entering the Game or accepting a prize
            including but not limited to the following types of loss which you may suffer as a result of your entry to the Game:
        </p>
        <p>
            a) loss which was not foreseeable to you and Liveball when you first applied for the Game (even if that loss results from Liveball's failure to comply with these Terms or its negligence);
        </p>
        <p>
            b) any business loss you may suffer, including loss of revenue, profits, or anticipated savings (whether those losses are the direct or indirect result of Liveball's default);
        </p>
        <p>
            c) loss which you suffer other than as a result of our failure to comply with these Terms or our negligence or breach of statutory duty; and/or
        </p>
        <p>
            d) any loss which you may suffer as a result of or in connection with or arising out of any Prize.
        </p>
        <p>
            34. Liveball has the authority to hand out prizes or not to hand out prizes to the winners and shall not be held liable.
        </p>
        <h4>Breach</h4>
        <p>
            35. Any breach by you of the Game Rules from time to time shall also be a breach of these Terms.
        </p>
        <p>
            In the event of any breach by you of these Terms Liveball reserves the right in its sole discretion to:
        </p>
        <p>a) permanently or temporarily refuse you entry to the Game;</p>
        <p>b) disqualify you from the Game;</p>
        <p>c) modify, delete and/or suspend your Registration;</p>
        <p>d) delete all related information associated with your Registration; and/or</p>
        <p>e) require any reasonable amendment to your Registration.</p>
        <p>All such decisions will be final and no correspondence will be entered into.</p>
        <p>
            36. If you are barred or disqualified from being Registered as a Player, you shall not be eligible to participate in the Game under any other identity or team name.
            Any such decision by Liveball shall be final.
        </p>
        <p>
            37. If any of these Terms are determined to be illegal, invalid, or otherwise enforceable then the remaining terms shall continue in full force and effect.
        </p>
        <p>
            38. These Terms shall be governed by and construed in accordance with the laws of the United Republic of Tanzania and the parties agree to submit to the exclusive jurisdiction of
            the Courts of the United Republic of Tanzania.
        </p>
      </div>
    )
  }
}

export default TermsAndConditions
