import React from 'react';
import { Collapse, Table } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'

//...components import
import Deadlines from "./Deadlines";
import Scoring from "./Scoring";

import 'antd/lib/collapse/style/css';


class Rules extends React.Component {
  render() {

    const { Panel } = Collapse;

    return (
      <div className={'rulesContainer'}>
        <div className={'rulesContainerBox'}>
          <h1>Rules</h1>
        </div>

        <div className={'rulesQnBox'}>
          <Collapse
            accordion
            bordered={false}
            defaultActiveKey={['1']}
            expandIcon={({ isActive }) => isActive ? <FontAwesomeIcon icon={faMinus} size={"0.5x"} color={'ffffff'} /> : <FontAwesomeIcon icon={faPlus} size={"0.6x"} color={'ffffff'} />}
            className="collapseItem"
          >
            <Panel header="Selecting Your initial squad" key="1" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <h3>Squad size</h3>
                <p>To join the game select a fantasy football squad of 15 players, consisting of: </p>
                <ul>
                  <li>2 Goalkeepers</li>
                  <li>5 Defenders</li>
                  <li>5 Midfielders</li>
                  <li>3 Forwards</li>
                </ul>

                <h5>Budget</h5>
                <p>The total value of your initial squad must not exceed Tsh.100 million.</p>

                <h5>Players Per Team</h5>
                <p>You can select up to 3 players from a single Premier League team.</p>
              </div>
            </Panel>


            <Panel header="Managing your squad" key="2" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <h5>Choosing Your Starting 11</h5>
                <p>From your 15 player squad, select 11 players by the Gameweek deadline to form your team.
                  All your points for the Gameweek will be scored by these 11 players, however if one or more doesn't play they may be automatically substituted.
                  Your team can play in any formation providing that 1 goalkeeper, at least 3 defenders and at least 1 forward are selected at all times.
                </p>

                <h5>Selecting a Captain and a Vice-Captain</h5>
                <p>From your starting 11 you nominate a captain and a vice-captain.Your captain's score will be doubled.
                  If your captain plays 0 minutes in the Gameweek, the captain will be changed to the vice-captain.
                  If both captain and vice-captain play 0 minutes in a Gameweek, then no player's score will be doubled.
                </p>

                {/*<h5>Prioritising Your Bench For Automatic Substitutions</h5>*/}
                {/*<p>Your substitutes provide cover for unforeseen events like injuries and postponements by automatically replacing starting players who don't play in a Gameweek.*/}
                {/*  Playing in a Gameweek means playing at least 1 minute or receiving a yellow / red card.*/}
                {/*  Based on the priorities you assign, automatic substitutions are processed at the end of the Gameweek as follows:*/}
                {/*</p>*/}
                {/*<ul>*/}
                {/*  <li>If your Goalkeeper doesn't play in the Gameweek, he will be substituted by your replacement Goalkeeper, if he played in the Gameweek.</li>*/}
                {/*  <li>If any of your outfield players don't play in the Gameweek, they will be substituted by the highest priority outfield substitute who played in the Gameweek and doesn't break the formation rules (eg.If your starting team has 3 defenders, a defender can only be replaced by another defender).</li>*/}
                {/*</ul>*/}
              </div>
            </Panel>

            <Panel header={'Transfers'} key="3" className={"rulesCollapsePanel"}>
              <div className={"rulesAnswerBox"}>
                <p>After selecting your squad you can buy and sell players in the transfer market.Unlimited transfers can be made at no cost until your first deadline.
                  After your first deadline you will receive 1 free transfer each Gameweek.Each additional transfer you make in the same Gameweek will deduct 4 points from your total score (Classic scoring) and match score (Head-to-Head scoring) at the start of the next Gameweek.
                  If you do not use your free transfer, you are able to make an additional free transfer the following Gameweek.If you do not use this saved free transfer in the following Gameweek, it will be carried over until you do.You can never have more than 1 saved transfer.
                </p>

                <h5>Wildcards</h5>
                <p>For information on wildcards please refer to the chips section of the rules.</p>

                <h5>Player Prices</h5>
                <p>
                  Player prices change during the season dependent on the popularity of the player in the transfer market. Player prices do not change until the season starts.
                  The price shown on your transfers page is a player's selling price. This selling price may be less than the player's current purchase price as a sell-on fee of 50% (rounded up to the nearest Tsh.2,674.49/=) will be applied on any profits made on that player
                </p>
              </div>
            </Panel>

            <Panel header="Chips" key="4" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>Chips can be used to potentially enhance your team's performance during the season.
                  Only one chip can be played in a single Gameweek. The chips available are as follows:
                </p>


                <div className={"columnScore"}>
                  <h5>Name</h5>
                  <h5>Effect</h5>
                </div>

                <div className={"rowScore"}>
                  <p>Bench Boost</p>
                  <p>The points scored by your bench players in the next Gameweek are included in your total</p>
                </div>

                <div className={"rowScore"}>
                  <p>Free Hit</p>
                  <p>Make unlimited free transfers for a single Gameweek. At the next deadline your squad is returned to how it was at the start of the Gameweek</p>
                </div>

                <div className={"rowScore"}>
                  <p>Triple Captain</p>
                  <p>Your captain points are tripled instead of doubled in the next Gameweek</p>
                </div>

                <div className={"rowScore"}>
                  <p>Wildcard</p>
                  <p>All transfers (including those already made) in the Gameweek are free of charge</p>
                </div>
              </div>
            </Panel>


            <Panel header="Deadlines" key="5" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>The deadline is set two hours before the first match of the gameweek starts</p>
                {/* <p>All changes to your team (starting 11, transfers, captain changes, substitiution priorities) must be made by the Gameweek deadline in order to take effect for that set of matches.
                  Deadlines are subject to change and will be 90 minutes before the kick-off time in the first match of the Gameweek.
                </p> */}
                {/* <Deadlines /> */}
              </div>
            </Panel>

            <Panel header="Scoring" key="6" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>During the season, your fantasy football players will be allocated points based on their performance in the Premier League.</p>
                <Scoring />
              </div>
            </Panel>

            <Panel header="Leagues" key="7" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>Coming soon...</p>
                {/* <p>After entering your squad, you can join and create leagues to compete with friends and other game players.</p>
                <h3>League Types</h3>
                <h4>Invitational Leagues and Cups</h4>
                <p>Invitational leagues and cups are the heart and soul of the game, where you compete against your friends.Just create a league and cup and then send out the unique code to allow your friends to join, easy!
                  You can compete in up to 25 private leagues.There's no limit on the number of teams in a single league.
                </p>

                <h4>Public Leagues</h4>
                <p>
                  Need an extra challenge?Then join a public league of randomly assigned teams.You can compete in up to 5 public leagues.
                </p>

                <h4>Global Leagues</h4>
                <p>You are automatically entered into the following global leagues: </p>
                <ul>
                  <li>The overall league featuring all registered teams</li>
                  <li>A league for fellow managers from your country</li>
                  <li>A league for supporters of your favourite Premier League team</li>
                  <li>A league for managers starting the same Gameweek as you</li>
                  <li>The Fantasy Cup (starts in Gameweek 17)</li>
                </ul>

                <h4>League Scoring</h4>
                <p>All leagues score on either a Classic or Head-to-Head basis.</p>

                <h4>Classic Scoring</h4>
                <p>
                  In a league with classic scoring, teams are ranked based on their total points in the game.You can join or leave a league with classic scoring at any point during the season.
                  In the event of a tie between teams, the team who has made the least amount of transfers will be positioned higher.Any transfers made using a wildcard or free hit will not count towards total transfers made.
                  Classic scoring leagues are run over a number of phases:
                </p>

                <div>
                  <div className={'columnLeagueScore'}>
                    <h5>Phase</h5>
                    <h5>First Gameweek</h5>
                    <h5>Last Gameweek</h5>
                  </div>

                  <div className={'rowLeagueScore'}>
                    <p>Overall</p>
                    <p>Gameweek 1</p>
                    <p>Gameweek 38</p>
                  </div>

                  <div className={'rowLeagueScore'}>
                    <p>August</p>
                    <p>Gameweek 1</p>
                    <p>Gameweek 3</p>
                  </div>

                  <div className={'rowLeagueScore'}>
                    <p>September</p>
                    <p>Gameweek 4</p>
                    <p>Gameweek 6</p>
                  </div>

                  <div className={'rowLeagueScore'}>
                    <p>October</p>
                    <p>Gameweek 7</p>
                    <p>Gameweek 10</p>
                  </div>

                  <div className={'rowLeagueScore'}>
                    <p>November</p>
                    <p>Gameweek 11</p>
                    <p>Gameweek 14</p>
                  </div>

                  <div className={'rowLeagueScore'}>
                    <p>December</p>
                    <p>Gameweek 15</p>
                    <p>Gameweek 20</p>
                  </div>

                  <div className={'rowLeagueScore'}>
                    <p>January</p>
                    <p>Gameweek 21</p>
                    <p>Gameweek 23</p>
                  </div>

                  <div className={'rowLeagueScore'}>
                    <p>February</p>
                    <p>Gameweek 24</p>
                    <p>Gameweek 27</p>
                  </div>

                  <div className={'rowLeagueScore'}>
                    <p>March</p>
                    <p>Gameweek 28</p>
                    <p>Gameweek 30</p>
                  </div>

                  <div className={'rowLeagueScore'}>
                    <p>April</p>
                    <p>Gameweek 31</p>
                    <p>Gameweek 35</p>
                  </div>

                  <div className={'rowLeagueScore'}>
                    <p>May</p>
                    <p>Gameweek 36</p>
                    <p>Gameweek 38</p>
                  </div>

                  <p>Any transfer point deductions in the Gameweek before a phase starts won't be deducted from the phase score. For example, any transfers made in Gameweek 3 (preparing for Gameweek 4) won't be deducted from your September score.</p>

                  <h4>Head-to-Head Scoring</h4>
                  <p>
                    In a league with Head-to-Head scoring, every team plays a match against another team in the league each Gameweek.The match result is based on the Gameweek score of each team minus any transfer points spent preparing for the Gameweek.
                    3 points are awarded for a win and 1 point for a draw, teams are then ranked on points earned in Head-to-Head matches.
                    Head-to-Head fixtures are generated at the start of the league's first Gameweek. Once these fixtures have been generated the league is locked and teams will not be able to join or leave.
                    If a Head-to-Head league has an odd number of teams then an average team will join the league to ensure each team has a fixture every Gameweek.This team will always score the Gameweek average.
                    In the event of a tie between teams, the team who has most game points will be positioned higher.
                  </p>

                  <h4>Head-to-Head Knock-out stage</h4>
                  <p>Optionally, a Head-to-Head league may end with a knock-out stage over up to 3 Gameweeks with the league winner decided by a match in the final Gameweek.The automatically entered average team will not enter the knock-out stage, their place being taken by the next placed team.</p>
                  <p>In the event of a tie between teams in a Head-to-Head knock-out match, the following tie-breaks will be used: </p>

                  <ol>
                    <li>Most goals scored in the Gameweek</li>
                    <li>Fewest goals conceded in the Gameweek</li>
                    <li>Virtual coin toss</li>
                  </ol>
                </div> */}

              </div>
            </Panel>

            <Panel header="Cups" key="8" className="rulesCollapsePanel">
              <div className={'rulesAnswerBox'}>
                <p>Coming soon...</p>
                {/* <p>Starting this season, you can create a League Cup for those who join your Invitational Classic Leagues. The League Cups will start in the second half of the season dependent on the size of your league and will conclude in Gameweek 38. There will also be Fan League Cups and Country Cups which you will automatically be entered into based on the team you support and your country of residence. All these Cups are in addition to the existing Overall Cup..</p>
                <p>All managers will be entered into their cup competitions. The starting round will be determined by the number of teams in the cup competition. All finals will be contested in Gameweek 38.</p>

                <p><strong>How Cups work</strong></p>
                <p>Each qualifying team will be randomly drawn against another in the first round. The winner (the team with the highest Gameweek score minus any transfer points), will progress to the second round and another random draw, the losers are out! This process continues until the final Gameweek when the two remaining teams contest the cup final.</p>

                <p>If a cup match is drawn, then the following tie-breaks will be applied until a winner is found:</p>
                <ol>
                  <li>Most goals scored in the Gameweek</li>
                  <li>Fewest goals conceded in the Gameweek</li>
                  <li>Virtual coin toss</li> */}
                {/* </ol> */}
              </div>
            </Panel>
          </Collapse>
        </div>

      </div>
    );
  }

}

export default Rules;