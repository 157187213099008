import React, { Component } from "react";
import { Helmet } from 'react-helmet-async';

export class SEO extends Component {

    render() {
        return (
            <Helmet>
            <meta name="keywords" content="Tanzania Premier league, TPL, Fantasy, Fantasy Tanzania, Tanzania Football, Soka la Tanzania, NBC premier league, Ligi kuu NBC, ligi kuu Tanzania bara, Tanzania league" />
                        
            {/* twitter */}
            <meta name="twitter:card" content="summary" />            
            <meta name="twitter:site" content="@LiveBall.10" />

            </Helmet>

        );
    }
}


export default SEO;


